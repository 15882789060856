$('[type="radio"].toggle__checkbox').on('click', ({ target }) => {
  const $this = $(target);
  let isChecked = $this.attr('data-checked');

  // Convert from String to Boolean
  isChecked = isChecked === 'true';

  $this
    .prop('checked', !isChecked)
    .attr('data-checked', !isChecked);
});
