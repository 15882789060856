const pixel = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

if (!Modernizr.testProp('objectFit')) {
  const objectFitSelectors = [
    '.category-card__img img',
    '.preload',
    '.trip-card__img img',
    '.img--limit',
    '.img--circle',
  ];

  const $images = $(objectFitSelectors.join(', '));

  $images.each((index, element) => {
    const $image = $(element);

    $image.css('background-image', `url(${$image.attr('src')})`);
    $image.css('background-size', 'cover');
    $image.css('background-position', 'center center');
    $image.attr('src', pixel);
  });
}
