$('#trip-filter').submit((event) => {
  event.preventDefault();

  const url = '/trips?' + $('#trip-filter').serialize();

  $.get(url, (result) => {
    const resultHtml = $($.parseHTML(result)).find('#trip-filter-result');
    $('#trip-filter-result').html(resultHtml);
  });
});
