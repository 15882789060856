$('[data-clampedwidth]').each(({ target }) => {
  const elem = $(target);
  const parentPanel = $(target).parent();

  function resizeCallback() {
    const sideBarNavWidth = $(parentPanel).width();
    elem.css('width', sideBarNavWidth);
  }

  resizeCallback();
  $(window).resize(resizeCallback);
});
