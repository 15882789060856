const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

$('#custom-trip-form').submit(({ target }) => {
  const $form = $(target);
  let isValid = true;

  $form.find('.required').each((_, field) => {
    if (isValid && !$(field).val()) {
      vex.dialog.alert($(field).attr('data-validation-message'));
      isValid = false;
    }
  });

  $form.find('.required.email').each((_, field) => {
    if (isValid && !emailRegex.test($(field).val())) {
      vex.dialog.alert($(field).data('validation-message'));
      isValid = false;
    }
  });

  if (!isValid) {
    return false;
  }

  $form.off('submit');
  $form.submit();
});
