$(document).ready(() => {
  if ($('.packery').length) {
    const $packery = $('.packery').packery({
      percentPosition: true,
      isOriginLeft: !$('body').hasClass('rtl'),
    });

    $(window).load(() => {
      $packery.packery();
    });
  }
});
