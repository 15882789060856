const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

$('#contact-form').submit((event) => {
  event.preventDefault();

  const $form = $(event.target);
  let isValid = true;

  $form.find('.required').each((_, field) => {
    if (isValid && !$(field).val()) {
      vex.dialog.alert($(field).data('validation-message'));
      isValid = false;
    }
  });

  $form.find('.required.email').each((_, field) => {
    if (isValid && !emailRegex.test($(field).val())) {
      vex.dialog.alert($(field).data('validation-message'));
      isValid = false;
    }
  });

  if (!isValid) {
    return false;
  }

  const $subscribeForm = $('#mailchimp-subscribe-form');
  const isOptedIn = $('#mailchimp-subscribe').prop('checked');
  const url = '//tripsatasia.us11.list-manage.com/subscribe/post?u=8575fae0c937a7266bacca945&amp;id=721ef7e1ac';

  $('#mce-FNAME').val($('#fromName').val());
  $('#mce-EMAIL').val($('#fromEmail').val());

  if (isOptedIn) {
    $.ajax({
      type: 'GET',
      url: url,
      data: $subscribeForm.serialize(),
      cache: false,
      dataType: 'jsonp',
      contentType: 'application/json; charset=utf-8',
      error: () => {},
      complete: () => {
        $form.off('submit');
        $form.submit();
      },
    });
  } else {
    $form.off('submit');
    $form.submit();
  }
});
