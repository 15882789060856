$(document).ready(() => {
  $.get(`${window.siteUrl}/wishlist`, (result) => {
    $('.wishlist-li').html($.parseHTML(result));

    $('[data-wishlist]').each((index, target) => {
      const $button = $(target);

      if ($.inArray($button.attr('data-wishlist'), wishlist) >= 0) {
        $button.children('i').removeClass('ss-star').addClass('ss-check');

        const tooltip = $button.attr('data-tooltip');

        if (tooltip) {
          $button.attr('data-tooltip', $button.attr('data-tooltip-alt'));
          $button.attr('data-tooltip-alt', $button.attr('data-tooltip'));
        }
      }
    });
  });
});

$('#trip-filter-result').delegate('[data-wishlist]', 'click', (event) => {
  const $button = $(event.currentTarget);
  const isSelected = $button.children('i').hasClass('ss-check');

  event.preventDefault();

  $button.children('i')
    .removeClass('ss-start ss-check')
    .addClass('ss-refresh spin');

  $button.blur();

  $.get($button.attr('href'), () => {
    $button.children('i')
      .removeClass('ss-refresh spin')
      .addClass(!isSelected ? 'ss-check' : 'ss-star');

    const tooltip = $button.attr('data-tooltip');

    if (tooltip) {
      $button.attr('data-tooltip', $button.attr('data-tooltip-alt'));
      $button.attr('data-tooltip-alt', $button.attr('data-tooltip'));
    }

    $.get(`${window.siteUrl}/wishlist`, (result) => {
      $('.wishlist-li').html($.parseHTML(result));
    });
  });
});
