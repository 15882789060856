$('[data-popup]').click(({ target }) => {
  if (!target.href) {
    return false;
  }

  const left = (window.screen ? Math.round(screen.width / 2 - 275) : 100);

  window.open(target.href, 'pswp_share', [
    'scrollbars=yes',
    'resizable=yes',
    'toolbar=n,',
    'location=yes',
    'width=550',
    'height=420',
    'top=100',
    `left=${left}`,
  ]);

  return false;
});
